<template>
  <div id="index">
    <div class="banner">
      <swiper style="height: 400px" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="item in advertList">
          <div
            class="banner"
            @click="toUrl(item.url)"
            :style="{
              'background-size: 100% 100%;background-position': 'center center',
              'background-image': 'url(' + backgroundImg(item.image) + ')',
              height: '100%',
            }"
          ></div>
        </swiper-slide>
      </swiper>
      <div class="banner_sub_width">
        <div class="banner_sub">
          <div class="to_user_center" v-if="$store.state.isLogin">
            <span @click="$router.push('/member/index')">进入会员中心</span>
          </div>
          <template v-else>
            <div class="login_avatar">
              <img src="@/assets/img/photo.png" alt="" />
              <p>Hi，欢迎来到{{ $store.state.tenantInfo.company }}!</p>
            </div>
            <div class="login_btn clearfix">
              <router-link to="login" class="left_login fl">
                会员登录
              </router-link>
              <router-link to="register" class="left_login fl">
                会员注册
              </router-link>
            </div>
          </template>
          <div class="sub_gg">
            <h3>公告</h3>
            <ul>
              <router-link
                tag="li"
                v-for="(item, key) in articleList"
                :to="'/news?id=' + item.id"
                >{{ item.title }}</router-link
              >
            </ul>
          </div>
          <div class="other_block">
            <div class="block" @click="$router.push('/member/inviter')">
              <img src="@/assets/img/tool_intro.png" alt="" />
              <p>邀请好友</p>
            </div>
            <div class="block" @click="$router.push('/member/guide')">
              <img src="@/assets/img/tool_step.png" alt="" />
              <p>代发指南</p>
            </div>
            <div class="block" @click="openQQ()">
              <img src="@/assets/img/tool_app.png" alt="" />
              <p>在线客服</p>
            </div>
            <div class="block" @click="$router.push('/news')">
              <img src="@/assets/img/tool_xinren.png" alt="" />
              <p>帮助中心</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main wrap">
      <div class="advertising">
        <div @click="$router.push('/goods/goodsList')">
          <img src="@/assets/img/01.jpg" alt="" />
        </div>
        <div @click="$router.push('/member/inviter')">
          <img src="@/assets/img/02.jpg" alt="" />
        </div>
        <div @click="$router.push('/goods/goodsList')">
          <img src="@/assets/img/03.jpg" alt="" />
        </div>
        <div @click="$router.push('/register')">
          <img src="@/assets/img/04.jpg" alt="" />
        </div>
      </div>
      <div class="recommend clearfix">
        <div class="item fl" @click="$router.push('/goods/goodsList')">
          <div class="recommend_left">
            <img src="@/assets/img/05-1.png" alt="" />
          </div>
        </div>
        <div
          class="item fl"
          v-for="item in hotgoodsList"
          @click="buyGoods(item)"
        >
          <dl>
            <dt>
              <img :src="item.cover | fullPath" alt="" />
            </dt>
            <dd class="title">
              {{ item.name }}
            </dd>
            <dd class="other clearfix">
              <span class="price fl"
                >￥{{
                  (parseFloat(item.cost) + parseFloat(item.profit)).toFixed(2)
                }}</span
              >
              <span class="wei fr">{{ item.weight }} kg/件</span>
            </dd>
            <dd class="btn">立即购买</dd>
          </dl>
        </div>
        <div class="item fl null" v-for="item in hotNull">
          <img
            src="@/assets/img/15.png"
            alt=""
            style="width: 170px; height: 210px"
          />
        </div>
      </div>
      <div class="new_goods_title">
        <span>新品上架</span>
      </div>
      <div class="recommend clearfix">
        <div class="item fl" @click="$router.push('/goods/goodsList')">
          <div class="recommend_left">
            <img src="@/assets/img/05-2.png" alt="" />
          </div>
        </div>
        <div
          class="item fl"
          v-for="item in newestGoodsList"
          @click="buyGoods(item)"
        >
          <dl>
            <dt>
              <img :src="item.cover | fullPath" alt="" />
            </dt>
            <dd class="title">
              {{ item.name }}
            </dd>
            <dd class="other clearfix">
              <span class="price fl"
                >￥{{
                  (parseFloat(item.cost) + parseFloat(item.profit)).toFixed(2)
                }}</span
              >
              <span class="wei fr">{{ item.weight }} kg/件</span>
            </dd>
            <dd class="btn">立即购买</dd>
          </dl>
        </div>
        <div class="item fl null" v-for="item in newNull">
          <img
            src="@/assets/img/15.png"
            alt=""
            style="width: 170px; height: 210px"
          />
        </div>
      </div>
      <div class="section4">
        <span>新闻动态</span>
        <div class="article_list">
          <div
            class="article_item"
            v-for="item in articleList2"
            @click="$router.push('/news/' + item.id + '?type=2')"
          >
            <div class="title">
              <a>{{ item.title }}</a>
              <p>{{ $time.getDate(item.createTime) }}</p>
            </div>
            <div class="summary">{{ item.summary }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="xian"></div>
  </div>
</template>

<script>
import request from "../../utils/request";

export default {
  name: "",
  data() {
    return {
      isLogin: false,
      articleList: [],
      articleList2: [],
      hotgoodsList: [],
      newestGoodsList: [],
      advertList: [],
      hotNull: 0,
      newNull: 0,
      swiperOptions: {
        loop: true,
        autoplay: true,
        initialSlide: 1,
      },
    };
  },
   created() {
    var hostName = window.location.host;
    let item = localStorage.getItem("tenantInfoid");
    if(item == null){
      this.getTenantInfo();
    }else{
      var inviteCode = this.$route.query.inviteCode;
      if (inviteCode) {
        localStorage.setItem("inviteCode", inviteCode);
      }
      let token = localStorage.getItem("dftToken");
      if (token) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
      this.getArticle();
      this.getArticle2();
      this.getHotGoodsList();
      this.getAdvertList();
      this.IsPC();
    }

  },
  mounted() {},
  watch: {},
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_index");
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1 && value.indexOf("http")) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.50:8080" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    getTenantInfo(){
      const locations = process.env.NODE_ENV == "development" ? "www.guyunm.com" : window.location.host
      request.post({
        url:'/ReportApi/tenantManager/tenantInfo',
        params:{
          locations
        },
        success: res => {
          localStorage.setItem("tenantInfoid", res.id);
          var inviteCode = this.$route.query.inviteCode;
          if (inviteCode) {
            localStorage.setItem("inviteCode", inviteCode);
          }
          let token = localStorage.getItem("dftToken");
          if (token) {
            this.isLogin = true;
          } else {
            this.isLogin = false;
          }
          this.getArticle();
          this.getArticle2();
          this.getHotGoodsList();
          this.getAdvertList();
          this.IsPC();
        },
        finally: () => {
        }
      })
    },

    getArticle() {
      this.$request.post({
        url: "/ReportApi/business/article/getArticleInfo",
        params: {
          pageNo: 1,
          pageSize: 3,
          type: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.articleList = res.data;
        },
      });
    },

    getArticle2() {
      this.$request.post({
        url: "/ReportApi/business/article/getArticleInfo",
        params: {
          pageNo: 1,
          pageSize: 6,
          type: 2,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.articleList2 = res.data;
        },
      });
    },

    getHotGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goods/getGoodsInfo",
        params: {
          pageNo: 1,
          pageSize: 11,
          sort: 3,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.hotgoodsList = res.data;
          if (this.hotgoodsList.length < 11) {
            this.hotNull = 11 - this.hotgoodsList.length;
          }
          this.getNewestGoodsList();
        },
      });
    },

    getNewestGoodsList() {
      this.$request.post({
        url: "/ReportApi/business/goods/getGoodsInfo",
        params: {
          pageNo: 2,
          pageSize: 11,
          sort: 5,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.newestGoodsList = res.data;
          if (this.newestGoodsList.length < 11) {
            this.newNull = 11 - this.newestGoodsList.length;
          }
        },
      });
    },
    buyGoods(item) {
      this.$store.commit("getGoodsInfo", item);
      this.$router.push("/member/send_order");
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert/getAdvertInfo",
        params: {
          type: 1,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          this.advertList = res;

        },
        finally: () => {},
      });
    },
    toUrl(url) {
      if(url ==null){
        return
      }
      if (url.indexOf("http") != -1) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
#index {
  .banner {
    position: relative;
    cursor: pointer;
    background-size: 100% 100%;
  }
  .banner_sub_width {
    top: 0;
    height: 400px;
    z-index: 999;
    left: 50%;
    margin-left: -600px;
    position: absolute;
    border-radius: 7px;
    .banner_sub {
      width: 220px;
      margin-top: 10px;
      height: 380px;
      background: #fff;
      border-radius: 5px;
    }
    .login_avatar {
      color: #999;
      text-align: center;
      font-size: 12px;
      img {
        border-radius: 50%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 50px;
        height: 50px;
      }
    }
    .to_user_center {
      width: 220px;
      padding: 48px 0;
      span {
        background: #ea242f;
        color: #fff;
        line-height: 30px;
        width: 180px;
        text-align: center;
        border-radius: 5px;
        margin: 0 auto;
        display: block;
        cursor: pointer;
      }
    }
    .login_btn {
      margin-top: 10px;
      .left_login {
        width: 70px;
        line-height: 25px;
        font-size: 14px;
        border: 1px solid #ea242f;
        border-radius: 3px;
        margin-left: 28px;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
        &:first-child {
          color: #ea242f;
          &:hover {
            background: #ea242f;
            color: #fff;
          }
        }
        &:last-child {
          background: #ea242f;
          color: #fff;
        }
      }
    }
    .sub_gg {
      padding: 8px 25px;
      box-sizing: border-box;
      margin-top: 10px;
      border-top: 1px solid #f1f1f1;
      font-size: 12px;
      h3 {
        font-size: 14px;
        margin-bottom: 5px;
      }
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 25px;
        color: #999;
      }
    }
    .other_block {
      .block {
        border-top: 1px solid #dedede;
        float: left;
        width: 110px;
        height: 63px;
        background: #fbfbfb;
        box-sizing: border-box;
        font-size: 12px;
        text-align: center;
        &:first-child,
        &:nth-child(3) {
          border-right: 1px solid #dedede;
        }
        img {
          width: 24px;
          height: 24px;
          margin-top: 8px;
          margin-bottom: 5px;
        }
        &:hover {
          background: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }
  .main {
    .advertising {
      display: flex;
      margin-top: 10px;
      div {
        flex: 1;
        img {
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .recommend {
      margin-top: 20px;
      .item {
        height: 290px;
        margin-left: 11px;
        margin-bottom: 12px;
        .recommend_left {
          height: 290px;
          width: 190px;
          img {
            width: 190px;
            height: 290px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        &:nth-child(1),
        &:nth-child(7) {
          margin-left: 0;
        }
        dl {
          width: 188px;
          border: 1px solid #eee;
          &:hover {
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
          }
          img {
            width: 188px;
            height: 188px;
          }
          dd {
            padding: 0 5px;
          }
          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #999;
            line-height: 28px;
          }
          .other {
            .price {
              font-size: 18px;
              color: #ff0036;
            }
            .wei {
              font-size: 14px;
              color: #666;
            }
          }
          .btn {
            width: 170px;
            line-height: 28px;
            text-align: center;
            color: #ea242f;
            border: 1px solid #ea242f;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 6px auto 10px auto;
            display: block;
            height: 28px;
            font-size: 14px;
            &:hover {
              color: #fff;
              background: #ea242f;
            }
          }
        }
      }
      .null {
        width: 188px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .new_goods_title {
      height: 40px;
      border-bottom: 1px solid #efefef;
      margin-top: 40px;
      margin-bottom: 30px;
      span {
        font-size: 20px;
        line-height: 28px;
        border-left: 5px solid #ea242f;
        padding-left: 15px;
        font-weight: bold;
        font-size: 20px;
      }
    }
    .section4 {
      position: relative;
      text-align: center;
      margin-bottom: 25px;
      background: #fff;
      & > span {
        position: relative;
        display: inline-block;
        margin: 25px auto;
        font-weight: 700;
        font-size: 22px;
        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          display: inline-block;
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(255, 166, 0, 0.929412)),
            to(#ea242f)
          );
          background: linear-gradient(
            90deg,
            rgba(255, 166, 0, 0.929412),
            #ea242f
          );
          width: 40%;
          border-radius: 50px;
          height: 4px;
        }
      }
      .article_list {
        text-align: left;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        .article_item {
          border: 1px solid #eee;
          padding: 10px 30px;
          margin: 10px 0;
          width: 44%;
          cursor: pointer;
          &:hover {
            box-shadow: 0 0 5px 1px #dcdfe6;
            .title a {
              color: #ea242f;
            }
          }
          .title {
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 10px;
          }
          .summary {
            color: #767676;
            font-size: 14px;
          }
          .title a {
            font-size: 18px;
            color: #333;
          }
          .title {
            p {
              color: #999;
            }
          }
        }
      }
    }
  }
  .xian {
    height: 5px;
    background: #eee;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
